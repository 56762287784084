import React from 'react';
import Button from '../../components/Button';
import BrandLogo from '../../components/Header/brand-logo.svg';
import s from './StoryBook.module.scss';

const StoryBook = () => (
  <div className={s.containerBg}>
    <div className={s.container}>
      Logo:
      <BrandLogo />
      <div className={s.test}>All component</div>
      <Button theme="grey">Grey Button</Button>
      <Button theme="blue">Blue Button</Button>
      Text Button:
      <a className="a" href="/">
        Button Text
      </a>
      <p className={s.largeParagraph}>P Large</p>
      <p>P tag</p>
      <p className={s.smallParagraph}>P Small</p>
      <h1>H1 text</h1>
      <h2>H2 text</h2>
      <h3>H3 text</h3>
      <h4>H4 text</h4>
    </div>
  </div>
);

export default StoryBook;
